import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./CustomarService.css";
import paymentImg from "../../../images/Payment-Options.png";
import cityBankLogo from "../../../images/city-bank-logo.png";
import dutchBankLogo from "../../../images/dutch-bangk-logo.jpg";
import whatsImg from "../../../images/ws.png";
import logo from "../../../images/skyfair_logo.png";
import LogoDesiner from "../../LogoDesinger/LogoDesiner";

const CustomarService = () => {
  const [customarService, setCustomarService] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    fetch("./CustomarService.JSON")
      .then((res) => res.json())
      .then((data) => setCustomarService(data));
  }, []);

  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  // handle search bar
  const handleSearch = (e) => {
    e.preventDefault();
    if (search === " ") {
      console.log("something");
    } else {
      const filterValue = customarService.filter(
        (onlineAg) =>
          onlineAg.id == search ||
          onlineAg.name.toLowerCase().includes(search.toLowerCase())
      );
      setCustomarService(filterValue);
    }
  };
  return (
    <div>
      <div className="main-section">
        <div className="content-section">
          <div className="">
            <div className="logo-section">
              <img src={logo} alt="" />
            </div>
          </div>

          {/* <LogoDesiner /> */}

          {/* navigation bar  */}
          <div className="navigation-bar">
            <ul>
              <li>
                <Link className="nav-menu" to="/home">
                  <i className="fa-solid fa-house"></i> HOME
                </Link>
              </li>
              <li>
                <Link className="nav-menu" to="/customarservice">
                  <i class="fa-solid fa-headphones-simple"></i> SERVICE
                </Link>
                {/* কাস্টমার সার্ভিস */}
              </li>

              <li>
                <Link className="nav-menu" to="/superAdmin">
                  <i class="fa-solid fa-user-graduate"></i> SUPER ADMIN
                </Link>
              </li>
              <li>
                <Link className="nav-menu" to="/admin">
                  <i class="fa-solid fa-user-graduate"></i> ADMIN
                </Link>
              </li>
              <li>
                <Link className="nav-menu" to="/SubAdmin">
                  <i class="fa-solid fa-user-graduate"></i> SUB ADMIN
                </Link>
                {/* সাব এডমিন */}
              </li>
              <li>
                <Link className="nav-menu" to="/superagent">
                  <i class="fa-solid fa-user-graduate"></i> SUPER
                </Link>
                {/* সুপার এজেন্ট */}
              </li>
              <li>
                <Link className="nav-menu" to="/onlineagent">
                  <i class="fa-solid fa-user-graduate"></i>MASTER
                </Link>
                {/* অনলাইন মাষ্টার এজেন্ট  */}
              </li>
            </ul>
          </div>

          {/* marquee the paragarap    */}
          {/* <marquee className='marquee__list'>
                        <strong>Welcome To <a className='marquee__link' href="https://www.baaji365.live/#/"> Baaji365.Live </a>
                            Enjoy Beeting In Match Odds, Fancy & Live Casino
                        </strong>
                    </marquee> */}

          {/* <div className="customar-service-list">
            <h4>6WICKETS CUSTOMER SERVICE LIST (TIME 10AM TO 10PM)</h4>
          </div> */}

          {/* marquee the paragarap    */}
          <section className="heading-section">
            <h4>
              <i class="fa-solid fa-microphone"></i>
              <p>NEWS</p>
            </h4>
            <marquee className="marquee__list">
              <strong>
                সহজ শর্তে এডমিন - সাব এডমিন - সুপার এজেন্ট - মাষ্টার এজেন্ট নিয়ে
                কাজ করতে চাইলে যোগাযোগ করুন
              </strong>
            </marquee>
          </section>

          <div className="services">
            <h1>SERVICES</h1>
          </div>

          <form onSubmit={handleSearch} className="search-agent">
            <p>Search:</p>
            <input
              type="text"
              placeholder=""
              onChange={handleChange}
              value={search}
            />
            <button type="submit">
              <i className="fa-solid fa-magnifying-glass search-icon"></i>
            </button>
          </form>
          <div className="customar-service-tabel">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">TYPE</th>
                  <th scope="col">PHONE APP LINK</th>
                  <th scope="col">PHONE NUMBER</th>
                  <th scope="col">COMPLAIN</th>
                </tr>
              </thead>
              <tbody>
                {customarService.map((customar) => (
                  <>
                    <tr key={customar.id}>
                      <td>{customar.id}</td>
                      <td>{customar.type}</td>

                      <td>
                        <div className="socail-icon">
                          <a href={customar.whatsapp}>
                            <img
                              id="agent_link"
                              src={whatsImg}
                              className="img-fluid icon-img"
                              alt="Whatsapp"
                            />
                          </a>
                        </div>
                      </td>
                      <td>{customar.number}</td>
                      <td>{customar.complain}</td>
                    </tr>
                    {/* <tr className="customarAgent">
                      <td colSpan={5}>
                        <p className="tabel-details">
                          কাস্টমার সার্ভিসে হোয়াটসঅ্যাপ ব্যাতিত অন্য কোন এপ এর
                          মাধ্যমে যোগাযোগ যাবে না এবং কাউকে আপনার একাউন্ট এর
                          পাসোয়ার্ড দিবেন না ।
                        </p>
                      </td>
                    </tr> */}
                  </>
                ))}
              </tbody>
            </table>
          </div>

          {/* <div className="tabel-description">
            <p>
              কাষ্টমার সার্ভিস এর কাউকেই আপনার পাসওয়ার্ড দিবেন না। শুধু আপনার
              bigwin এর ইউজার নেম দিবেন - যদি প্রয়োজন হয়।
            </p>
            <ul>
              <li>
                *** স্বাধারন তথ্য জানার জন্য "General Inquiry" এর সাথে যোগাযোগ
                করার জন্য অনুরোধ করা হল।
              </li>
              <li>
                *** বেট বিষয়ক তথ্য জানার জন্য "General Inquiry" এর সাথে যোগাযোগ
                করার জন্য অনুরোধ করা হল।
              </li>
              <li>
                *** পয়েন্ট স্বম্পর্কিত কোন অভিযোগ এর জন্য "CUSTOMER SERVICE"
                সাথে যোগাযোগ করার জন্য অনুরোধ করা হল।
              </li>
              <li>
                *** বেট স্বম্পর্কিত কোন অভিযোগ এর জন্য "CUSTOMER SERVICE" সাথে
                যোগাযোগ করার জন্য অনুরোধ করা হল।
              </li>
              <li>
                *** এজেন্ট স্বম্পর্কিত কোন অভিযোগ এর জন্য "CS MANAGER" সাথে
                যোগাযোগ করার জন্য অনুরোধ করা হল।
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default CustomarService;
