import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./OnlineAgent.css";
import paymentImg from "../../images/Payment-Options.png";
import cityBankLogo from "../../images/city-bank-logo.png";
import dutchBankLogo from "../../images/dutch-bangk-logo.jpg";
import whatsImg from "../../images/ws.png";
import messengerImg from "../../images/Messenger-Logo-2018.png";
import fbImg from "../../images/PngItem_200308.png";
import logo from "../../images/skyfair_logo.png";
import LogoDesiner from "../LogoDesinger/LogoDesiner";

const OnlineAgent = () => {
  // state declear
  const [onlineAgent, setOnlineAgent] = useState([]);
  const [displayAgent, setDisplayAgent] = useState([]);
  const [search, setSearch] = useState("");
  // data load on fake data
  useEffect(() => {
    fetch("./OnlineMasterAget.JSON")
      .then((res) => res.json())
      .then((data) => {
        setOnlineAgent(data);
        // setDisplayAgent(data);
      });
  }, []);

  useEffect(() => {
    setDisplayAgent(shuffleArray(onlineAgent));
  }, [onlineAgent]);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  };

  // input handle onchange
  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  // handle search bar
  const handleSearch = (e) => {
    e.preventDefault();

    const filterValue = onlineAgent.filter(
      (onlineAg) =>
        onlineAg.id == search ||
        onlineAg.name.toLowerCase().includes(search.toLowerCase())
    );
    setDisplayAgent(filterValue);
  };

  return (
    <div>
      <div className="main-section" id="online__agent">
        <div className="content-section">
          {/* header section  */}
          <div className="">
            <div className="logo-section">
              <img src={logo} alt="" />
            </div>
          </div>

          {/* <LogoDesiner /> */}

          {/* navigation bar  */}
          {/* navigation bar  */}
          <div className="navigation-bar">
            <ul>
              <li>
                <Link className="nav-menu" to="/home">
                  <i className="fa-solid fa-house"></i> HOME
                </Link>
              </li>
              <li>
                <Link className="nav-menu" to="/customarservice">
                  <i class="fa-solid fa-headphones-simple"></i> SERVICE
                </Link>
                {/* কাস্টমার সার্ভিস */}
              </li>

              <li>
                <Link className="nav-menu" to="/superAdmin">
                  <i class="fa-solid fa-user-graduate"></i> SUPER ADMIN
                </Link>
              </li>
              <li>
                <Link className="nav-menu" to="/admin">
                  <i class="fa-solid fa-user-graduate"></i> ADMIN
                </Link>
              </li>
              <li>
                <Link className="nav-menu" to="/SubAdmin">
                  <i class="fa-solid fa-user-graduate"></i> SUB ADMIN
                </Link>
                {/* সাব এডমিন */}
              </li>
              <li>
                <Link className="nav-menu" to="/superagent">
                  <i class="fa-solid fa-user-graduate"></i> SUPER
                </Link>
                {/* সুপার এজেন্ট */}
              </li>
              <li>
                <Link className="nav-menu" to="/onlineagent">
                  <i class="fa-solid fa-user-graduate"></i>MASTER
                </Link>
                {/* অনলাইন মাষ্টার এজেন্ট  */}
              </li>
            </ul>
          </div>

          {/* marquee the paragarap    */}
          <section className="heading-section">
            <h4>
              <i class="fa-solid fa-microphone"></i>
              <p>NEWS</p>
            </h4>
            <marquee className="marquee__list">
              <strong>
                সহজ শর্তে এডমিন - সাব এডমিন - সুপার এজেন্ট - মাষ্টার এজেন্ট নিয়ে
                কাজ করতে চাইলে যোগাযোগ করুন
              </strong>
            </marquee>
          </section>

          <div className="services">
            <h1>MASTER AGENT LIST</h1>
          </div>
          <form onSubmit={handleSearch} className="search-agent">
            <p>Search:</p>
            <input
              type="text"
              placeholder=""
              onChange={handleChange}
              value={search}
            />
            <button type="submit">
              <i className="fa-solid fa-magnifying-glass search-icon"></i>
            </button>
          </form>

          {/* online agent table  */}
          <div className="customar-service-tabel">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">ID NO</th>
                  <th scope="col">TYPE</th>
                  <th scope="col">NAME</th>
                  {/* <th scope="col">7* RATING</th> */}
                  <th scope="col">PHONE APP LINK</th>
                  <th scope="col">PHONE NUMBER</th>
                  <th scope="col">COMPLAIN</th>
                </tr>
              </thead>
              <tbody>
                {/* style={{ backgroundColor: index % 2 === 0 ? "#fff" : "#c5c8fd" }} */}
                {!displayAgent ? (
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  displayAgent.map((online, index) => (
                    <>
                      <tr className="onlineAgent-table" key={online.id}>
                        <td>
                          {online.id}{" "}
                          <span className="online-name">{online.Lname}</span>
                        </td>
                        <td>{online.type}</td>
                        <td> {online.name}</td>
                        {/* <td>
                          <ul className="tabel-rating">
                            <li>
                              <i class="fa-solid fa-star"></i>
                            </li>
                            <li>
                              <i class="fa-solid fa-star"></i>
                            </li>
                            <li>
                              <i class="fa-solid fa-star"></i>
                            </li>
                            <li>
                              <i class="fa-solid fa-star"></i>
                            </li>
                            <li>
                              <i class="fa-solid fa-star"></i>
                            </li>
                            <li>
                              <i class="fa-solid fa-star"></i>
                            </li>
                            <li>
                              <i class="fa-solid fa-star"></i>
                            </li>
                          </ul>
                        </td> */}
                        <td>
                          <div className="socail-icon">
                            {/* <a href={online.facebook}>
                              <img
                                src={fbImg}
                                id="fb-icon"
                                className="img-fluid icon-img"
                                alt="facebook"
                              />
                            </a> */}
                            <a href={online.messenger}>
                              <img
                                id="agent_linkm"
                                src={messengerImg}
                                className="img-fluid icon-img"
                                alt="Whatsapp"
                              />
                            </a>

                            <a href={online.whatsapp}>
                              <img
                                id="agent_link"
                                src={whatsImg}
                                className="img-fluid icon-img"
                                alt="Whatsapp"
                              />
                            </a>
                          </div>
                        </td>
                        <td>{online.number}</td>
                        <td>
                          <Link
                            to={``} ///complain/${online.id}
                            className="complain-btn"
                          >
                            COMPLAIN
                          </Link>
                          {/*  */}
                        </td>
                      </tr>
                      {/* <tr className="customarAgent">
                        <td colSpan={7}>
                          <p className="tabel-details">
                            এজেন্ট এর সাথে হোয়াটসঅ্যাপ ও মেসেঞ্জার ব্যতিত অন্য
                            কোনো মাধ্যমে যোগাযোগ করা যাবে না এবং যোগাযোগ করলে তা
                            গ্রহনযোগ্য হবে না ।
                          </p>
                        </td>
                      </tr> */}
                    </>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnlineAgent;
