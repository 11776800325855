import React, { useState } from "react";
import { Link } from "react-router-dom";
import whatsImg from "../../images/ws.png";
import messengerImg from "../../images/Messenger-Logo-2018.png";
import paymentImg from "../../images/Payment-Options.png";
import cityBankLogo from "../../images/city-bank-logo.png";
import dutchBankLogo from "../../images/dutch-bangk-logo.jpg";
import "./Admin.css";
import logo from "../../images/skyfair_logo.png";
import fbImg from "../../images/PngItem_200308.png";
import { useEffect } from "react";
import LogoDesiner from "../LogoDesinger/LogoDesiner";

const Admin = () => {
  const [admin, setAdmin] = useState([]);
  const [superAdmin, setSupeAdmin] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    fetch("./SubAdmin.JSON")
      .then((res) => res.json())
      .then((data) => setAdmin(data));
  }, []);

  useEffect(() => {
    setSupeAdmin(shuffleArray(admin));
  }, [admin]);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  };

  // input handle onchange
  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  // handle search bar
  const handleSearch = (e) => {
    e.preventDefault();
    if (search === " ") {
      console.log("something");
    } else {
      const filterValue = admin.filter(
        (onlineAg) =>
          onlineAg.id == search ||
          onlineAg.name.toLowerCase().includes(search.toLowerCase())
      );
      setAdmin(filterValue);
    }
  };

  return (
    <div>
      <div className="main-section">
        <div className="content-section">
          <div className="">
            <div className="logo-section">
              <img src={logo} alt="" />
            </div>
          </div>

          {/* <LogoDesiner /> */}

          {/* navigation bar  */}
          <div className="navigation-bar">
            <ul>
              <li>
                <Link className="nav-menu" to="/home">
                  <i className="fa-solid fa-house"></i> HOME
                </Link>
              </li>
              <li>
                <Link className="nav-menu" to="/customarservice">
                  <i class="fa-solid fa-headphones-simple"></i> SERVICE
                </Link>
                {/* কাস্টমার সার্ভিস */}
              </li>

              <li>
                <Link className="nav-menu" to="/superAdmin">
                  <i class="fa-solid fa-user-graduate"></i> SUPER ADMIN
                </Link>
              </li>
              <li>
                <Link className="nav-menu" to="/admin">
                  <i class="fa-solid fa-user-graduate"></i> ADMIN
                </Link>
              </li>
              <li>
                <Link className="nav-menu" to="/SubAdmin">
                  <i class="fa-solid fa-user-graduate"></i> SUB ADMIN
                </Link>
                {/* সাব এডমিন */}
              </li>
              <li>
                <Link className="nav-menu" to="/superagent">
                  <i class="fa-solid fa-user-graduate"></i> SUPER
                </Link>
                {/* সুপার এজেন্ট */}
              </li>
              <li>
                <Link className="nav-menu" to="/onlineagent">
                  <i class="fa-solid fa-user-graduate"></i>MASTER
                </Link>
                {/* অনলাইন মাষ্টার এজেন্ট  */}
              </li>
            </ul>
          </div>

          {/* marquee the paragarap    */}
          <section className="heading-section">
            <h4>
              <i class="fa-solid fa-microphone"></i>
              <p>NEWS</p>
            </h4>
            <marquee className="marquee__list">
              <strong>
                সহজ শর্তে এডমিন - সাব এডমিন - সুপার এজেন্ট - মাষ্টার এজেন্ট নিয়ে
                কাজ করতে চাইলে যোগাযোগ করুন
              </strong>
            </marquee>
          </section>

          {/* <div className="customar-service-list">
            <h4>BIGWIN SUB ADMIN LIST </h4>
          </div> */}
          {/* <div className="message">
            <p>
              WHATSAPP, FACEBOOK, MESSENGER ব্যাতিত অন্য কোন এপ এর মাধ্যমে
              যোগাযোগ বা লেনদেন করা যাবে না এবং করলে তা গ্রহনযোগ্য হবে না{" "}
            </p>
          </div> */}
          <div className="services">
            <h1>SUB ADMIN LIST</h1>
          </div>

          {/* search bar  */}
          <form onSubmit={handleSearch} className="search-agent">
            <p>Search:</p>
            <input
              type="text"
              placeholder=""
              onChange={handleChange}
              value={search}
            />
            <button type="submit">
              <i className="fa-solid fa-magnifying-glass search-icon"></i>
            </button>
          </form>
          <div className="customar-service-tabel">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">ID NO</th>
                  <th scope="col">TYPE</th>
                  <th scope="col">NAME</th>
                  <th scope="col">PHONE APP LINK</th>
                  <th scope="col">PHONE NUMBER</th>
                  <th scope="col">COMPLAIN</th>
                </tr>
              </thead>
              <tbody>
                {superAdmin.map((admin) => {
                  return (
                    <>
                      <tr className="admin-tabel" key={admin.id}>
                        <td>{admin.id}</td>
                        <td>{admin.type}</td>
                        <td>{admin.name}</td>
                        {/* <td>
                          <ul className="tabel-rating">
                            <li>
                              <i class="fa-solid fa-star"></i>
                            </li>
                            <li>
                              <i class="fa-solid fa-star"></i>
                            </li>
                            <li>
                              <i class="fa-solid fa-star"></i>
                            </li>
                            <li>
                              <i class="fa-solid fa-star"></i>
                            </li>
                            <li>
                              <i class="fa-solid fa-star"></i>
                            </li>
                            <li>
                              <i class="fa-solid fa-star"></i>
                            </li>
                            <li>
                              <i class="fa-solid fa-star"></i>
                            </li>
                          </ul>
                        </td> */}
                        <td>
                          <div className="socail-icon">
                            {/* <a href="">
                              <img
                                src={fbImg}
                                id="fb-icon"
                                className="img-fluid icon-img"
                                alt="facebook"
                              />
                            </a> */}

                            <a href="">
                              <img
                                id="agent_linkm"
                                src={messengerImg}
                                className="img-fluid icon-img"
                                alt="Whatsapp"
                              />
                            </a>

                            <a href={admin.whatsapp}>
                              <img
                                id="agent_link"
                                src={whatsImg}
                                className="img-fluid icon-img"
                                alt="Whatsapp"
                              />
                            </a>
                          </div>
                        </td>
                        <td className="COLOR">{admin.number}</td>
                        <td className="complain-btn">COMPLAIN</td>
                      </tr>
                    </>
                  );
                })}
                {/* <tr className="customarAgent">
                  <td colSpan={4}>
                    <p className="tabel-details">
                      সাব এডমিন এর সাথে হোয়াটসঅ্যাপ ও মেসেঞ্জার ব্যতিত অন্য কোনো
                      মাধ্যমে যোগাযোগ করা যাবে না এবং যোগাযোগ করলে তা গ্রহনযোগ্য
                      হবে না ।
                    </p>
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>
          {/* <div className="tabel-description">
                        <p>* এজেন্ট ও বেট স্বম্পর্কিত কোন অভিযোগ এর জন্য "Admin" সাথে যোগাযোগ করার জন্য অনুরোধ করা হল।</p>
                    </div> */}
        </div>
      </div>
    </div>
  );
};

export default Admin;
