import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import whatsImg from "../../images/ws.png";
import messengerImg from "../../images/Messenger-Logo-2018.png";
import fbImg from "../../images/PngItem_200308.png";
import logo from "../../images/skyfair_logo.png";
import bottomPic from "../../images/bottom_pic.jpg";
import social_media from "../../images/social-media.png";
import facebook_group from "../../images/facebook_group-removebg-preview.png";

const Header = () => {
  const [superAgent, setSuperAgent] = useState([]);

  useEffect(() => {
    fetch("./OnlineMasterAget.JSON")
      .then((res) => res.json())
      .then((data) => {
        setSuperAgent(data);
      });
  }, []);

  let arrayM = Math.floor(Math.random() * 5 + 1);
  const findData = superAgent.find((ele) => ele?.id === arrayM);

  return (
    <div className="main-section" id="home-page">
      <div className="content-section">
        <div className="">
          <div className="logo-section">
            <img src={logo} alt="" />
          </div>
        </div>

        {/* <LogoDesiner /> */}

        {/* navigation bar  */}
        <div className="navigation-bar">
          <ul>
            <li>
              <Link className="nav-menu" to="/home">
                <i className="fa-solid fa-house"></i> HOME
              </Link>
            </li>
            <li>
              <Link className="nav-menu" to="/customarservice">
                <i class="fa-solid fa-headphones-simple"></i> SERVICE
              </Link>
              {/* কাস্টমার সার্ভিস */}
            </li>

            <li>
              <Link className="nav-menu" to="/superAdmin">
                <i class="fa-solid fa-user-graduate"></i> SUPER ADMIN
              </Link>
            </li>
            <li>
              <Link className="nav-menu" to="/admin">
                <i class="fa-solid fa-user-graduate"></i> ADMIN
              </Link>
            </li>
            <li>
              <Link className="nav-menu" to="/SubAdmin">
                <i class="fa-solid fa-user-graduate"></i> SUB ADMIN
              </Link>
              {/* সাব এডমিন */}
            </li>
            <li>
              <Link className="nav-menu" to="/superagent">
                <i class="fa-solid fa-user-graduate"></i> SUPER
              </Link>
              {/* সুপার এজেন্ট */}
            </li>
            <li>
              <Link className="nav-menu" to="/onlineagent">
                <i class="fa-solid fa-user-graduate"></i>MASTER
              </Link>
              {/* অনলাইন মাষ্টার এজেন্ট  */}
            </li>
          </ul>
        </div>

        {/* marquee the paragarap    */}
        <section className="heading-section">
          <h4>
            <i class="fa-solid fa-microphone"></i>
            <p>NEWS</p>
          </h4>
          <marquee className="marquee__list">
            <strong>
              সহজ শর্তে এডমিন - সাব এডমিন - সুপার এজেন্ট - মাষ্টার এজেন্ট নিয়ে
              কাজ করতে চাইলে যোগাযোগ করুন
            </strong>
          </marquee>
        </section>

        {/* quick master  */}

        {/* <div className="quick-master">
          <div className="quick-master-title">
            <h1>QUICK SITE OWNER</h1>
          </div>

          <div className="quick-master-description">
            <div className="table table-bordered">
              <table className="data">
                <tbody>
                  {
                    <tr key={findData?.id} style={{ backgroundColor: "#eee" }}>
                      <td>SITE OWNER</td>
                      <td>TAQDAR</td>
                      <td>
                        <div className="socail-icon">
                          <a href={findData?.facebook}>
                            <img
                              src={fbImg}
                              id="fb-icon"
                              className="img-fluid icon-img"
                              alt="facebook"
                            />
                          </a>

                          <a href={findData?.messenger}>
                            <img
                              id="agent_linkm"
                              src={messengerImg}
                              className="img-fluid icon-img"
                              alt="Whatsapp"
                            />
                          </a>

                          <a href={findData?.whatsapp}>
                            <img
                              id="agent_link"
                              src={whatsImg}
                              className="img-fluid icon-img"
                              alt="Whatsapp"
                            />
                          </a>
                        </div>
                      </td>
                      <td>+892054365</td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div> */}

        {/* acount info  */}

        <div className="account-info">
          <div className="quick-master-title">
            {/* <img src={iconImg} alt="" /> */}
            <h1>কিভাবে একাউন্ট খুলবেন ?</h1>
          </div>
          <div className="account-info-content">
            <div className="main-content">
              <p>
                <a className="color-red" href="https://maza247.live/">
                  Maza247
                </a>{" "}
                -{" "}
                <a className="color-yellow" href="https://www.lc247.vip/">
                  lc247
                </a>{" "}
                -{" "}
                <a className="color-blue" href="https://velkii365.com/">
                  Velkii365
                </a>{" "}
                -{" "}
                <a className="color-pink" href="https://betbuzzz365.bet/">
                  Betbuzzz365
                </a>{" "}
                সাইটে একাউন্ট করতে হলে আপনার এজেন্ট এর মাধ্যমে একাউন্ট খুলতে
                হবে। এজেন্ট এর মাধ্যমেই টাকা ডিপোজিট এবং উইথড্র করতে হবে। আপনি
                যে এজেন্ট এর কাছ থেকে একাউন্ট খুলবেন তার সাথেই সব সময় লেনদেন
                করতে হবে। ঠিক কোন এজেন্ট কে টাকা দিবেন এবং কিভাবে তার সাথে
                লেনদেন করবেন তার বুঝতে হলে আপনার নিচের তথ্য গুলো পড়া জরুরী
              </p>
              {/* <ul>
                                <li><i class="fa-solid fa-star"></i>আপনার নাম</li>
                                <li><i class="fa-solid fa-star"></i>আপনার ইমেইল আইডি (যদি থাকে আর না থাকলে এজেন্ট বানিয়ে দিবে)</li>
                                <li><i class="fa-solid fa-star"></i>আপনার ফোন নাম্বার</li>
                            </ul> */}
            </div>
          </div>
        </div>

        {/* agent list  */}

        <div className="account-info">
          <div className="quick-master-title">
            {/* <img src={iconImg} alt="" /> */}
            <h1>এজেন্ট লিস্ট ?</h1>
          </div>
          <div className="account-info-content">
            <div className="main-content">
              <p>
                একাউন্ট খুলতে নিচের অনলাইন এজেন্ট লিস্ট এ ক্লিক করুন। এজেন্ট
                লিষ্ট এর এজেন্ট দের সাথে ইউজার দের শুধু মাত্র হোয়াটসাপ ও
                মেসেঞ্জার এর মাধ্যমে যোগাযোগ করতে হবে। হোয়াটসাপ ও মেসেঞ্জার ছাড়া
                অন্য কোন মাধ্যমে যোগাযোগ করলে বা লেনদেন করলে তা গ্রহনযোগ্য হবে
                না। হোয়াটসাপ ও মেসেঞ্জার এ যোগাযোগ করতে হলে এজেন্ট লিস্ট থেকে
                হোয়াটসাপ ও মেসেঞ্জার আইকন উপরে ক্লিক করুন অনলাইন মাষ্টার এজেন্ট
                লিস্টঃ
                <br />
                <Link to="/onlineagent" className="agent_color">
                  অনলাইন মাষ্টার এজেন্ট লিস্টঃ
                </Link>{" "}
              </p>
            </div>
          </div>
        </div>

        {/* Agent different */}

        <div className="account-info">
          <div className="quick-master-title">
            {/* <img src={iconImg} alt="" /> */}
            <h1>এজেন্ট কয় প্রকার ?</h1>
          </div>

          <div className="account-info-content">
            <div className="main-content">
              <p>
                {" "}
                <Link to="/superagent" className="agent_color">
                  অনলাইন সুপার এজেন্ট লিস্টঃ
                </Link>{" "}
                সুপার এজেন্ট রা, ইউজার একাউন্ট এবং মাষ্টার এজেন্ট একাউন্ট খুলে
                দিতে পারেন। কোন সুপার এজেন্ট এর নামে অভিযোগ থাকলে - সরাসরি এডমিন
                কে জানাতে হবে। উপরে মেনু তে এডমিন লিষ্ট দেয়া আছে।
              </p>
              <p>
                <Link to="/onlineagent" className="agent_color">
                  অনলাইন মাষ্টার এজেন্ট লিস্টঃ
                </Link>{" "}
                অনলাইন মাষ্টার এজেন্ট রা, শুধু ইউজার একাউন্ট একাউন্ট খুলে দিতে
                পারেন। কোন মাষ্টার এজেন্ট এর নামে অভিযোগ থাকলে - সরাসরি সুপার
                এজেন্ট এর কাছে অভিযোগ করতে হবে।{" "}
                <a
                  href="https://facebook.com/groups/velki365.live/"
                  className="agent_color"
                >
                  {" "}
                  বিস্তারিত জানতে আমাদের ফেইসবুক গ্রুপ এ যোগ দিতে এই লিঙ্ক এ
                  ক্লিক করুন।{" "}
                </a>
              </p>
              <p>
                লোকাল মাষ্টার এজেন্ট লিস্টঃ লোকাল মাষ্টার এজেন্ট রা, শুধু ইউজার
                একাউন্ট একাউন্ট খুলে দিতে পারেন। কিন্তু তাদের সাথে লেনদেন
                প্রতিটি ইউজার কে নিজ দায়িত্বে লেনদেন করতে হবে। তাদের নামে কোন
                অভিযোগ কারো কাছে করা যাবে না।
              </p>
              <br />
              <p>
                লোকাল মাষ্টার এজেন্টঃ এই সব এজেন্ট সাধারনত – নিজের এলাকায় বা
                পরিচিত দের সাথে লেনদেন করে । যারা আগে বাজি ধরিয়ে দিত, তাদের কেই
                মুলত লোকাল এজেন্ট দেয়া হয়েছে। লোকাল এজেন্ট রা অনলাইনে আসে না
                এবং তারা তাদের পরিচয় গোপন রাখতে চায়। লোকাল এজেন্ট দের সাথে
                অনলাইনে কোন ভাবেই লেনদেন করবেন না, আর করে থাকলে তার দায়ভার
                পুরোটাই আপনার।
              </p>
            </div>
          </div>
        </div>

        <div className="account-info">
          <div className="quick-master-title">
            {/* <img src={iconImg} alt="" /> */}
            <h1>Social Media Link​</h1>
          </div>

          <div className="account-info-content">
            <div className="main-content">
              <div className="social_media">
                <div>
                  <a href="">
                    <img src={social_media} alt="" />
                    <p>Facebook Page</p>
                  </a>
                </div>
                <div>
                  <a href="https://facebook.com/groups/velki365.live/">
                    <img src={facebook_group} alt="" />
                    <p>Facebook Group</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* aovijog  */}
        <div className="account-info">
          <div className="quick-master-title">
            {/* <img src={iconImg} alt="" /> */}
            <h1>RULES:​</h1>
          </div>

          <div className="account-info-content">
            <div className="main-content">
              <img className="bottom_pic" src={bottomPic} alt="" />
            </div>
          </div>
        </div>

        {/* facebook group  */}
        {/* <div className="facebook-group-section">
          <h1>
            Jᴏɪɴ ᴏᴜʀ Oғғɪᴄɪᴀʟ <br />
            Fᴀᴄᴇʙᴏᴏᴋ Gʀᴏᴜᴘ
          </h1>
          <p>
            আমাদের সকল এডনিন, সাব এডমিন,সুপার এজেন্ট, মাষ্টার এজেন্ট ও ইউজার
            আমাদের ফেইসবুক গ্রুপে যুক্ত আছে।{" "}
            <strong>
              {" "}
              <a href="https://bajie365.live/" className="agent_color">
                bajie365.live
              </a>{" "}
              -{" "}
              <a href="https://baajix365.live/" className="baajix-color">
                baajix365.live
              </a>{" "}
              -{" "}
              <a href="https://bazi365.live/" className="bazie365-color">
                bazi365.live
              </a>{" "}
              -{" "}
              <a href="https://baaji365.site/" className="baajie365-color">
                baaji365.site
              </a>{" "}
            </strong>
            এর সকল আপডেট পেতে এক্ষুনি নিচের facebook চিহ্নিত বাটনে ক্লিক করে
            আমাদের অফিসিয়াল ফেসবুক গ্রুপে যুক্ত হয়ে যান।
          </p>
          <button className="facebook-btn">
            <a href="https://facebook.com/groups/velki365.live">
              <i class="fa-brands fa-square-facebook"></i>
              <span>FACEBOK GROUP</span>
            </a>
          </button>
          <p className="bottom_part_of_facebook">
            আপনার সকল জিজ্ঞাসা ও জানার বিষয়ে আমাদের গ্রুপে পোস্ট করুন। সকল
            এজেন্টদের থেকে আপনার পছন্দের এজেন্টকে বেছে নিন এবং নিরাপদে বেটিং
            করুন।
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default Header;
